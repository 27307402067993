
import filters from "@/mixins/filters";
import { mapGetters } from "vuex";

export default {
  mixins: [filters],
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings",
      allMarks: "marks/marks/allMarks",
      banks: "banks/banks"
    }),
    t_bank() {
      return this.banks.find(item => item.id === 3) || {};
    }
  },
  async fetch() {
    try {
      await this.getBanks();
    } catch (error) {
      console.log(error);
    }
  }
};
